<template>
    <v-container fluid class="p-4" style="padding-bottom:80px">
      <v-row>
        <v-col cols="12">
            <v-breadcrumbs :items="breadcumbs">
                <template v-slot:item="{ item }">
                    <v-breadcrumbs-item
                        :to="item.href"
                        class="text-subtitle-2 crumb-item"
                        :disabled="item.disabled"
                        exact
                    >
                        {{ item.text }}
                    </v-breadcrumbs-item>
                </template>
            </v-breadcrumbs>
        </v-col>
      </v-row>
        <v-row>
            <v-col cols="12">
                    <v-card-title class="pb-1 pt-1">
                        Filter
                    </v-card-title>
                    <v-progress-linear
                        :active="loading"
                        :indeterminate="loading"
                        absolute
                        bottom
                        color="deep-purple accent-4"
                    ></v-progress-linear>
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12" xs="12" sm="5" md="3">
                                <v-autocomplete
                                    solo
                                    clearable
                                    v-model="merchandise"
                                    :items="merchandises"
                                    item-value="md_id"
                                    item-text="first_name"
                                    label="Merchandiser"
                                    class="ma-0 pa-0"
                                    hide-details=true
                                    :disabled="disabled"
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="5" md="3">
                                <v-menu
                                    ref="modal"
                                    v-model="modal"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="auto"
                                    >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        solo
                                        v-model="date_from"
                                        label="Start Date"
                                        persistent-hint
                                        prepend-inner-icon="mdi-calendar"
                                        v-bind="attrs"
                                        v-on="on"
                                        class="ma-0 pa-0"
                                        hide-details=true
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="date_from"
                                        no-title
                                        @input="modal = false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" xs="12" sm="5" md="3">
                                <v-menu
                                    ref="modal_to"
                                    v-model="modal_to"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="auto"
                                    >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        solo
                                        v-model="date_to"
                                        label="End Date"
                                        persistent-hint
                                        prepend-inner-icon="mdi-calendar"
                                        v-bind="attrs"
                                        v-on="on"
                                        class="ma-0 pa-0"
                                        hide-details=true
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="date_to"
                                        no-title
                                        @input="modal_to = false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" xs="12" sm="3" md="2">
                                <v-btn class="mt-1" 
                                color="info" elevation="2" large @click="getPullData(1,10)">Search</v-btn>
                            </v-col>
                            <!-- <v-col cols="12" xs="12" sm="5" md="3">
                                <v-btn
                                    class="mr-2 mt-1"
                                    color="error"
                                    elevation="2"
                                    large
                                    @click="getPullData(1,10)"
                                >Search</v-btn>
                            </v-col> -->
                        </v-row>
                    </v-container>
            </v-col>
            <v-col cols="12" style="padding-bottom: 100px">
                <v-card style="border-top: 3px solid #1565C0">
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12">
                                <v-card-title>
                                    Result
                                    <v-spacer></v-spacer>
                                    <v-btn 
                                        class="pa-5"
                                        small
                                        color="primary"
                                        :disabled="disabledExport"
                                    >
                                        <download-excel
                                            :fetch           = "exportExcel"
                                            :fields="merchandiser_field"
                                            :before-generate = "startDownload"
                                            :before-finish   = "finishDownload">
                                            <v-icon>
                                                mdi-file-excel
                                            </v-icon>
                                            Export to Excel
                                        </download-excel>  
                                        
                                    </v-btn>
                                </v-card-title>
                                <v-data-table
                                    :headers="headers"
                                    :items="merchandisers"
                                    :options.sync="options"
                                    :loading="loading"
                                    loading-text="Please wait, retrieving data"
                                    :server-items-length="totalItems"
                                    :page.sync="pagination"
                                    page-count="10"
                                    class="elevation-1"
                                >
                                    <template v-slot:[`item.actions`]="{ item }">
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on }">
                                                <v-icon small class="mr-2" @click="showItem(item)" v-on="on">
                                                    mdi-eye
                                                </v-icon>
                                            </template>
                                            <span>Show Detail</span>
                                        </v-tooltip>
                                    </template>
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
            <v-dialog
                v-model="dialog"
                persistent
                max-width="1400px"
            >
                <v-card>
                    <v-card-title>
                        <v-row align="center" justify="center" style="margin-top: -16px;">
                            <v-col cols="12" xs="12" sm="6" md="4" style="background: #053d76;border-bottom-left-radius: 30px;border-bottom-right-radius: 30px;">
                                <h4 class="text-white text-center" style="font-weight:700">
                                    Merchandiser
                                </h4>
                                <h5 class="text-white text-center">Detail Form Isian Merchandiser</h5>
                            </v-col>
                        </v-row>
                    </v-card-title>
                    <v-card-text>
                        <v-container fluid>
                            <v-row class="mt-5" justify="end">
                                <v-col cols="12" xs="12" sm="6" md="3">
                                    <h6 class="blue-lcd">Nama MD</h6>
                                    <v-text-field
                                        solo
                                        label="Nama MD"
                                        persistent-hint
                                        readonly
                                        v-model="name_md"
                                        class="ma-0 pa-0 border-12"
                                    >
                                    </v-text-field> 
                                </v-col>
                                <v-col cols="12" xs="12" sm="6" md="3">
                                    <h6 class="blue-lcd">Kota</h6>
                                    <v-text-field
                                        solo
                                        label="Kota"
                                        persistent-hint
                                        v-model="city_md"
                                        readonly
                                        class="ma-0 pa-0 border-12"
                                    >
                                    </v-text-field> 
                                </v-col>
                            </v-row>
                            <v-row class="mt-lg-5">
                                <v-col cols="12" xs="12" sm="6" md="4" class="pt-0 pb-0">
                                    <h6 class="blue-lcd">Nama Toko</h6>
                                    <v-text-field
                                        solo
                                        label="Nama Toko"
                                        persistent-hint
                                        readonly
                                        v-model="name_mitra"
                                        class="ma-0 pa-0 border-12"
                                    >
                                    </v-text-field>    
                                </v-col>
                                <v-col cols="12" xs="12" sm="6" md="4" class="pt-0 pb-0">
                                    <h6 class="blue-lcd">Pemilik Toko</h6>
                                    <v-text-field
                                        solo
                                        label="Pemilik Toko"
                                        persistent-hint
                                        readonly
                                        v-model="pemilik_mitra"
                                        class="ma-0 pa-0 border-12"
                                    >
                                    </v-text-field>   
                                </v-col>
                                <v-col cols="12" xs="12" sm="6" md="4" class="pt-0 pb-0">
                                    <h6 class="blue-lcd">No HP</h6>
                                    <v-text-field
                                        solo
                                        label="No HP"
                                        persistent-hint
                                        readonly
                                        v-model="no_hp"
                                        class="ma-0 pa-0 border-12"
                                    >
                                    </v-text-field> 
                                </v-col>
                                <v-col cols="12" xs="12" sm="6" md="6" class="pt-0 pb-0">
                                    <h6 class="blue-lcd">Alamat Toko</h6>
                                    <v-text-field
                                        solo
                                        label="Alamat Toko"
                                        persistent-hint
                                        readonly
                                        v-model="address"
                                        class="ma-0 pa-0 border-12"
                                    >
                                    </v-text-field>   
                                </v-col>
                                <v-col cols="12" xs="12" sm="6" md="4" class="pt-0 pb-0">
                                    <h6 class="blue-lcd">Kota</h6>
                                    <v-text-field
                                        solo
                                        label="Kota"
                                        persistent-hint
                                        readonly
                                        v-model="city"
                                        class="ma-0 pa-0 border-12"
                                    >
                                    </v-text-field>   
                                </v-col>
                            </v-row>
                            <v-row class="mt-3">
                                <v-col cols="12" xs="12" sm="12" md="12" class="pt-0 pb-0">
                                    <h6 class="blue-lcd">Jenis Toko</h6>
                                    
                                    <div class="d-flex mt-0 pt-0">
                                        <v-checkbox
                                            v-model="type_mitra"
                                            label="Toko Khusus Baja Ringan"
                                            value="tkbj"
                                            class="mr-8 mt-0"
                                            readonly
                                        ></v-checkbox>
                                        <v-checkbox
                                            v-model="type_mitra"
                                            label="Toko Besi"
                                            value="tb"
                                            class="mr-8 mt-0"
                                            readonly
                                        ></v-checkbox>
                                        <v-checkbox
                                            v-model="type_mitra"
                                            label="Toko Bahan Bangunan"
                                            value="tbb"
                                            class="mr-8 mt-0"
                                            readonly
                                        ></v-checkbox>                                  
                                    </div>
                                </v-col>
                            </v-row>                                    
                            <v-row class="mt-2">
                                <v-col cols="12" xs="12" sm="12" md="12" class="pt-0 pb-0">
                                    <h6 class="blue-lcd">DATA  PRODUK</h6>
                                    <v-divider></v-divider>
                                </v-col>
                                <v-col cols="12" xs="12" sm="12" md="12" class="pt-0 pb-0">
                                    <h6 class="blue-lcd">Produk yang Dijual</h6>
                                    <div class="d-flex mt-0 pt-0">
                                        <v-checkbox
                                            v-model="product"
                                            label="Atap"
                                            value="atap"
                                            class="mr-8 mt-0"
                                            readonly
                                        ></v-checkbox>
                                        <v-checkbox
                                            v-model="product"
                                            label="Floor Deck"
                                            value="floor"
                                            class="mr-8 mt-0"
                                            readonly
                                        ></v-checkbox>
                                        <v-checkbox
                                            v-model="product"
                                            label="Kanal C"
                                            value="kanal"
                                            class="mr-8 mt-0"
                                            readonly
                                        ></v-checkbox>
                                        <v-checkbox
                                            v-model="product"
                                            label="Reng"
                                            value="reng"
                                            class="mr-8 mt-0"
                                            readonly
                                        ></v-checkbox>
                                        <v-checkbox
                                            v-model="product"
                                            label="Hollow"
                                            value="hollow"
                                            class="mr-8 mt-0"
                                            readonly
                                        ></v-checkbox>
                                        <v-checkbox
                                            v-model="product"
                                            label="Lain lain"
                                            value="lain"
                                            class="mr-8 mt-0"
                                            readonly
                                        ></v-checkbox>
                                    </div>
                                </v-col>
                                <v-col cols="12" xs="12" sm="12" md="12" class="pt-2 pb-0">
                                    <h6 class="blue-lcd">MEREK PRODUK & BAHAN BAJA RINGAN</h6>
                                    <v-divider></v-divider>
                                    <v-simple-table
                                        fixed-header
                                        class="mb-5"
                                    >
                                        <template v-slot:default>
                                            <thead>
                                                <tr>
                                                    <th class="text-left">
                                                        <h6 class="blue-lcd">MEREK PRODUK</h6>
                                                    </th>
                                                    <th class="text-left">
                                                        <h6 class="blue-lcd">BAHAN BAJA RINGAN</h6>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr
                                                    v-for="(merk_product, index) in merk_products"
                                                    :key="index"
                                                >
                                                    <td class="text-left">{{ merk_product.merk }}</td>
                                                    <td class="text-left">
                                                        {{ merk_product.bahan }}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table>
                                </v-col>
                            </v-row>
                            <v-row class="mt-3">
                                <v-col cols="12" xs="12" sm="12" md="12" class="pt-0 pb-0">
                                    <h6 class="blue-lcd">Omzet Toko per Bulan</h6>
                                    <v-divider></v-divider>
                                </v-col>
                                <v-col cols="12" xs="12" sm="6" md="4" class="pt-0 pb-0">
                                    <h6 class="blue-lcd">Atap ( meter )</h6>

                                    <v-text-field
                                        solo
                                        label="Atap ( meter )"
                                        persistent-hint
                                        readonly
                                        v-model="atap"
                                        class="ma-0 pa-0 border-12"
                                    >
                                    </v-text-field>    
                                </v-col>
                                <v-col cols="12" xs="12" sm="6" md="4" class="pt-0 pb-0">
                                    <h6 class="blue-lcd">Floor Dek ( meter )</h6>

                                    <v-text-field
                                        solo
                                        label="Floor Dek ( meter )"
                                        persistent-hint
                                        readonly
                                        v-model="floor"
                                        class="ma-0 pa-0 border-12"
                                    >
                                    </v-text-field>    
                                </v-col>
                                <v-col cols="12" xs="12" sm="6" md="4" class="pt-0 pb-0">
                                    <h6 class="blue-lcd">Kanal C (batang)</h6>
                                    <v-text-field
                                        solo
                                        label="Kanal C (batang)"
                                        persistent-hint
                                        readonly
                                        v-model="kanal"
                                        class="ma-0 pa-0 border-12"
                                    >
                                    </v-text-field>  
                                </v-col>
                                <v-col cols="12" xs="12" sm="6" md="4" class="pt-0 pb-0">
                                    <h6 class="blue-lcd">Reng ( batang )</h6>
                                    <v-text-field
                                        solo
                                        label="Reng ( batang )"
                                        persistent-hint
                                        readonly
                                        v-model="reng"
                                        class="ma-0 pa-0 border-12"
                                    >
                                    </v-text-field>    
                                </v-col>
                                <v-col cols="12" xs="12" sm="6" md="4" class="pt-0 pb-0">
                                    <h6 class="blue-lcd">Hollow ( batang )</h6>
                                    <v-text-field
                                        solo
                                        label="Hollow ( batang )"
                                        persistent-hint
                                        readonly
                                        v-model="hollow"
                                        class="ma-0 pa-0 border-12"
                                    >
                                    </v-text-field>    
                                </v-col>
                                <v-col cols="12" xs="12" sm="6" md="4" class="pt-0 pb-0">
                                    <h6 class="blue-lcd">Lain-lain</h6>
                                    <v-text-field
                                        solo
                                        label="Lain-lain"
                                        persistent-hint
                                        readonly
                                        v-model="lain"
                                        class="ma-0 pa-0 border-12"
                                    >
                                    </v-text-field>    
                                </v-col>
                                <v-col cols="12" xs="12" sm="12" md="12" class="pt-0 pb-0">
                                    <h6 class="blue-lcd">SOSIALISASI / KEGIATAN</h6>
                                    <div class="d-flex mt-2 pt-0">
                                            <v-checkbox
                                            v-model="kegiatan"
                                            label="Sosialisasi Medsos & Hallo ZINIUM"
                                            value="medsos"
                                            class="mr-8 mt-0"
                                            readonly
                                        ></v-checkbox>
                                        <v-checkbox
                                            v-model="kegiatan"
                                            label="Sosialisasi Rejeki Toko ZINIUM (RTZ)"
                                            value="rtz"
                                            class="mr-8 mt-0"
                                            readonly
                                        ></v-checkbox>                                                    
                                    </div>
                                    <div class="d-flex mt-0 pt-0">
                                        <v-checkbox
                                            v-model="kegiatan"
                                            label="Sosialisasi Santunan Tukang Baja Ringan (STBR)"
                                            value="STBR"
                                            class="mr-8 mt-0"
                                            readonly
                                        ></v-checkbox>
                                        <v-checkbox
                                            v-model="kegiatan"
                                            label="Sosialisasi Branding Kendaraan"
                                            value="sbk"
                                            class="mr-8 mt-0"
                                            readonly
                                        ></v-checkbox>
                                    </div>
                                    <div class="d-flex mt-0 pt-0">
                                        <v-checkbox
                                            v-model="kegiatan"
                                            label="Edukasi bahan ZINIUM"
                                            value="edukasi"
                                            class="mr-8 mt-0"
                                            readonly
                                        ></v-checkbox>
                                        <v-checkbox
                                            v-model="kegiatan"
                                            label="Lainnya"
                                            value="lain"
                                            class="mr-8 mt-0"
                                            readonly
                                        ></v-checkbox>
                                    </div>
                                </v-col>
                            </v-row>
                            <v-row class="mb-2">
                                <v-col
                                    cols="12"
                                >
                                    <h6 class="blue-lcd">Catatan</h6>
                                    <v-textarea
                                        outlined
                                        name="input-7-4"
                                        label="Catatan"
                                        v-model="remark"
                                        readonly
                                    ></v-textarea>
                                </v-col>
                            </v-row>
                            <v-row class="mt-1 mb-2">
                                <h6 class="blue-lcd">Gambar</h6>
                                <v-col 
                                cols="12" xs="12" sm="6" md="3"
                                v-for="(gambar, key) in images" :key="key"
                                >
                                    <v-card class="border-12">
                                        <v-card-text>
                                            <v-img
                                                :lazy-src="gambar.src"
                                                max-height="300"
                                                max-width="300"
                                                :src="gambar.src"
                                            ></v-img>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" xs="12" sm="6" md="4" class="pt-0 pb-0">
                                    <v-text-field
                                        label="Longitude"
                                        v-model="longitude"
                                        disabled
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" xs="12" sm="6" md="4" class="pt-0 pb-0">
                                    <v-text-field
                                        label="Latitude"
                                        v-model="latitude"
                                        disabled
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" xs="12" sm="6" md="4" class="pt-0 pb-0">
                                    <v-text-field
                                        label="Current Address"
                                        v-model="current_address"
                                        disabled
                                    ></v-text-field>
                                </v-col>
                                <!-- <v-col cols="12">
                                    <v-btn block class="border-12" color="primary" @click="getLocation()">Get Current Location</v-btn>
                                </v-col> -->
                                <v-col cols="12">
                                    <div id="map_canvas" style="height:415px; width:100%; border: 1px solid grey;"></div>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="blue darken-1"
                            text
                            @click="dialog = false"
                        >
                            Close
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </v-container>
</template>
<script>
export default {
    data() {
        return {
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false
            },
            breadcumbs: [
                {
                text: 'Sunrise Steel',
                disabled: false,
                href: '/admin/sr',
                },
                {
                text: 'Merchandiser Activity',
                disabled: true,
                href: 'breadcrumbs_link_2',
                },
                {
                text: 'Activity Report',
                disabled: true,
                href: 'breadcrumbs_link_2',
                },
            ],
            loading: false,
            merchandise: '',
            merchandises: [],
            sales: '',
            date_from: '',
            modal: false,
            date_to: '',
            modal_to: false,
            merchandisers: [],
            headers: [
                { text: 'TR date', value: 'tr_date' },
                { text: 'TR ID', value: 'tr_id' },
                { text: 'Merchandise', value: 'first_name' },
                { text: 'Customer', value: 'nama_customer' },
                { text: 'Owner', value: 'nama_owner' },
                { text: 'Address', value: 'alamat' },
                { text: 'Remark', value: 'remark' },
                { text: 'Actions',  value: 'actions', sortable: false },
            ],
            totalItems: 10,
            options: {},
            pagination: 1,
            dialog: false,
            name_mitra: '',
            pemilik_mitra: '',
            no_hp: '',
            address: '',
            city: '',
            type_mitra: '',
            atap: '',
            floor: '',
            kanal: '',
            reng: '',
            hollow: '',
            lain: '',
            product: [],
            merk: '',
            bahan_bjlas: '',
            kegiatan: [],
            remark: '',
            longitude: '',
            latitude: '',
            current_address: '',
            mymap: '',
            md_id: '',
            data_var: {
                entity_id : 'SR',
                appl_id : 'WEBSR'
            },
            display: 'none',
            disabled: false,
            city_md: '',
            name_md: '',
            images: [],
            merk_products: [],
            merchandiser_field: {
                'TR date' : 'tr_date',
                'TR ID' : 'tr_id',
                'Merchandise' : 'first_name',
                'Kota MD' : 'kota_md',
                'Customer' : 'nama_customer',
                'Owner' : 'nama_owner',
                'Address' : 'alamat',
                'Kota' : 'city',
                'No HP' : 'no_hp',
                'Jenis Toko' : 'jenis_toko',
                'Produk yang dijual' : 'sold_product',
                'Merk Produk' : 'merk_product',
                'Bahan Produk' : 'bahan_product',
                'Omzet Atap per bulan' : 'omzet_atap',
                'Omzet Floor Deck per bulan' : 'omzet_floor',
                'Omzet Kanal C per bulan' : 'omzet_kanal',
                'Omzet Reng per bulan' : 'omzet_reng',
                'Omzet Hollow per bulan' : 'omzet_hollow',
                'Omzet Lain per bulan' : 'omzet_lain',
                'TOKO MENGETAHUI BAHAN BAJA RINGAN MEREK ZINIUM ?' : 'merk_zinium',
                'Kegiatan' : 'activity',
                'Latitude' : 'store_latitude',
                'Longitude' : 'store_longitude',
                'Remark' : 'remark',
            },
            disabledExport: false
        }
    },
    mounted(){
        // this.getMerchandise()
        // this.getEnvConf()
        this.$store.dispatch('setOverlay', false)

        axios.get(`${process.env.VUE_APP_URL}/api/get_group_user?entity_id=SR&appl_id=WEBSR`, { 
            headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
        })
        .then(res => {
            console.log(res.data.data);

            if (res.data.data === 'MERCHANDISERSR') {
                this.getMerchandiseUser()
            } else {
                this.getMerchandise()
            }
        });
    },
    methods:{    
        getEnvConf(){
            let data = this.data_var;

            this.$store.dispatch("getVariabelUser", { data }).then(res => {

                if(res.data.data != null){
                    for (let i = 0; i < res.data.data.length; i++) {

                        if (res.data.data[i]['var_id'] === 'MDID') {
                            if (res.data.data[i]['var_value'] != null) {
                                this.merchandise = res.data.data[i]['var_value']
                                this.disabled = true
                                this.getPullData(1,10)
                            }
                        }

                    }
                }

            })
        },
        search(){

        },
        showItem(item){
            this.kegiatan = []
            this.product = []

            this.name_md = item.first_name
            this.city_md = item.kota_md
            this.atap = this.$store.getters.convertToCurrency(item.omzet_atap) 
            this.floor = this.$store.getters.convertToCurrency(item.omzet_floor) 
            this.kanal = this.$store.getters.convertToCurrency(item.omzet_kanal) 
            this.reng = this.$store.getters.convertToCurrency(item.omzet_reng) 
            this.hollow = this.$store.getters.convertToCurrency(item.omzet_hollow) 
            this.lain = this.$store.getters.convertToCurrency(item.omzet_lain) 
            this.remark = item.remark

            if (item.activity_medsos == 'Y') {
                this.kegiatan.push('medsos')
            }
            if (item.activity_rtz == 'Y') {
                this.kegiatan.push('rtz')
            }
            if (item.activity_sbk == 'Y') {
                this.kegiatan.push('sbk')
            }
            if (item.activity_STBR == 'Y') {
                this.kegiatan.push('STBR')
            }
            if (item.activity_edukasi == 'Y') {
                this.kegiatan.push('edukasi')
            }
            if (item.activity_lain == 'Y') {
                this.kegiatan.push('lain')
            }

            this.updateCustomer(item)          
            this.updateImages(item)          

        },
        async updateCustomer(value){
            await axios.get(`${process.env.VUE_APP_URL}/api/sr/merchandiser/master/customer?customer_id=${value.customer_id}&md_id=${value.md_id}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.dialog = true 

                this.name_mitra = res.data.data.nama_customer
                this.pemilik_mitra = res.data.data.nama_owner
                this.no_hp = res.data.data.no_hp
                this.address = res.data.data.alamat
                this.city = res.data.data.city
                this.customer_id = res.data.data.customer_id

                if (res.data.data.jenis_toko_bahan_bangunan == 'Y') {
                    this.type_mitra = 'tbb'
                } else if (res.data.data.jenis_toko_baja_ringan == 'Y') {
                    this.type_mitra = 'tkbj'
                } else if (res.data.data.jenis_toko_besi == 'Y') {
                    this.type_mitra = 'tb'
                }

                if (res.data.data.sold_product_atap == 'Y') {
                    this.product.push('atap')
                }
                if (res.data.data.sold_product_floor == 'Y') {
                    this.product.push('floor')
                }
                if (res.data.data.sold_product_hollow == 'Y') {
                    this.product.push('hollow')
                }
                if (res.data.data.sold_product_kanal == 'Y') {
                    this.product.push('kanal')
                }
                if (res.data.data.sold_product_reng == 'Y') {
                    this.product.push('reng')
                }
                if (res.data.data.sold_product_lain == 'Y') {
                    this.product.push('lain')
                }

                this.merk = res.data.data.merk_profil_baja_ringan
                this.bahan_bjlas = res.data.data.bahan_bjlas

                this.latitude = res.data.data.store_latitude
                this.longitude = res.data.data.store_longitude
                this.current_address = res.data.data.lt_location
                this.merk_products = res.data.products

                this.showPosition(res.data.data)


            });
        },
        async updateImages(value){
            this.images = []
            await axios.get(`${process.env.VUE_APP_URL}/api/sr/merchandiser/images?tr_id=${value.tr_id}&customer_id=${value.customer_id}&md_id=${value.md_id}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.images = res.data
            });
        },
        addMerchandiser(){
            this.dialog = true
        },
        async showPosition(position){
            if (this.mymap != '') {
                this.mymap.off();
                this.mymap.remove();
            }
            
            await axios.get(`https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${position.store_latitude}&lon=${position.store_longitude}`)
            .then(res => {
                var address = res.data.address.village +', '+ res.data.address.state_district +', '+ res.data.address.state;
                this.current_address = address
                this.longitude = res.data.lon
                this.latitude = res.data.lat

                this.mymap = L.map('map_canvas').setView([position.store_latitude, position.store_longitude], 13);
        
                L.tileLayer(`https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=${process.env.TK_SRS}`, {
                    maxZoom: 18,
                    attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, ' +
                        '<a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, ' +
                        'Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
                    id: 'mapbox/streets-v11',
                    tileSize: 512,
                    zoomOffset: -1
                }).addTo(this.mymap);

                L.marker([position.store_latitude, position.store_longitude],
                    {draggable: true,        // Make the icon dragable
                    //title: 'Hover Text',     // Add a title
                    opacity: 0.5}            // Adjust the opacity
                    )
                    .addTo(this.mymap)
                    .bindPopup('<b>Your current location</b><br>'+address)
                    .openPopup();

            });
        },
        async getPullData(page = 1, itemsPerPage = 10, search){

            this.loading = true

            var start_date = this.date_from ? new Date(this.date_from).toISOString().substr(0, 10).replace(/-/g,"") : ''
            var end_date = this.date_to ? new Date(this.date_to).toISOString().substr(0, 10).replace(/-/g,"") : ''

            await axios.get(`${process.env.VUE_APP_URL}/api/sr/merchandiser/visit-report?md_id=${this.merchandise ? this.merchandise : ""}&start_date=${start_date ? start_date : ""}&end_date=${end_date ? end_date : ""}&page=${page > 1 ? page : ''}&pagination=${itemsPerPage}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.loading = false
                this.merchandisers = res.data.data
                this.totalItems = res.data.total
                this.pagination = res.data.current_page
                this.display = 'block'
            })
        },
        async getMerchandiseUser(){
            await axios.get(`${process.env.VUE_APP_URL}/api/sr/merchandiser/master?username=${this.$store.state.user.username}&pagination=50`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.merchandises = res.data.data
                this.merchandise = res.data.data[0].md_id
                this.disabled = true
            });
        },
        async getMerchandise(){
            await axios.get(`${process.env.VUE_APP_URL}/api/sr/merchandiser/master?md_id=${this.md_id}&pagination=50`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.merchandises = res.data.data
            });
        }, 
        async exportExcel(){

            var url = `${process.env.VUE_APP_URL}/api/sr/merchandiser/export-visit-report?md_id=${this.merchandise ? this.merchandise : ""}`
            const response = await axios.get(url, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })

            return response.data;
        },
        startDownload(){
            this.loading = true
            this.disabledExport=true
            this.$store.dispatch('setOverlay', true)
        },
        finishDownload(){
            this.loading = false
            this.disabledExport=false
            this.$store.dispatch('setOverlay', false)

        },

    },
    watch: {
        options: {
            handler () {
                const { page, itemsPerPage } = this.options
                if (this.display == 'block') {
                    this.getPullData(page, itemsPerPage)
                }
            },
            deep: true,
        }
    }
}
</script>